import * as React from "react"

// markup
const Hero = () => {
    return (
        <section className="my-8 lg:my-24">
            <div className="max-w-screen-xl px-4 lg:px-12 mx-auto lg:text-center">
                {/*
                <a href="#application-ui" className="bg-gray-100 rounded-full py-1 px-1 pr-4 mb-4 text-sm text-gray-700 inline-flex items-center justify-between hover:bg-gray-200" role="alert">
                    <span className="text-xs bg-purple-600 rounded-full text-white px-4 py-1.5 mr-3">New</span> <span className="text-sm font-medium">Application UI</span> <svg className="w-5 h-5 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                </a>
                */}
                <h1 className="font-bold lg:font-extrabold text-4xl lg:text-6xl lg:leading-tight text-gray-900 lg:text-center 2xl:px-36 mb-4 lg:mb-8">Premium Wordpress theme for your business</h1>
                <p className="text-gray-500 font-normal lg:text-center text-lg lg:text-2xl xl:px-32 mb-10">Take your business website to the next level with our enterprise-level, professionally designed templates. Our fully responsive full-page templates and 60+ page sections are specifically crafted for Full-site Editing, allowing you to create a custom website with ease.</p>
                <div className="flex flex-col md:flex-row lg:justify-center mb-8">
                    <a href="#pricing" className="text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center">Get Bergify PRO</a>
                    <a href="#pricing" className="text-gray-600 bg-white-100 hover:bg-gray-100 hover:text-blue-600 border border-gray-200 font-semibold rounded-lg text-base px-6 py-2.5 text-center inline-flex justify-center items-center">Which version is right for you?</a>
                </div>
                <div className="text-center">
                    <span className="text-gray-500 text-xs font-normal">DESIGNED BY <a href="https://flowbite.com?ref=bergify.com" className="hover:underline" target="_blank">FLOWBITE</a> AND DEVELOPED BY <a href="https://www.hormart.cz" className="hover:underline" target="_blank">HORMART</a></span>
                </div>
                {/* <iframe className="mt-12 lg:mt-20 w-full max-w-4xl mx-auto relative rounded-3xl border border-gray-300 shadow-2xl yt-video" src="https://www.youtube.com/embed/mTAupMv-3t8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
            </div>
        </section>
    )
}

export default Hero
