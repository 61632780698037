import pro404PNG from '../images/full-pages/pro-404.png';
import problogPNG from '../images/full-pages/pro-blog.png';
import procontact1PNG from '../images/full-pages/pro-contact-1.png';
import procontact2PNG from '../images/full-pages/pro-contact-2.png';
import prolanding1PNG from '../images/full-pages/pro-landing-1.png';
import prolanding2PNG from '../images/full-pages/pro-landing-2.png';
import prolanding3PNG from '../images/full-pages/pro-landing-3.png';
import propostPNG from '../images/full-pages/pro-post.png';
import propricing1PNG from '../images/full-pages/pro-pricing-1.png';
import propricing2PNG from '../images/full-pages/pro-pricing-2.png';
import proservices1PNG from '../images/full-pages/pro-services-1.png';
import proservices2PNG from '../images/full-pages/pro-services-2.png';
import proteam1PNG from '../images/full-pages/pro-team-1.png';
import proteam2PNG from '../images/full-pages/pro-team-2.png';
import protestimonialsPNG from '../images/full-pages/pro-testimonials.png';

export default [
    {
        key: 1,
        name: "Landing Page 1",
        png: prolanding1PNG,
        link: "https://demo.bergify.com/pro/landing-page/",
        class: "w-full rounded-lg"
    },
    {
        key: 2,
        name: "Landing Page 2",
        png: prolanding2PNG,
        link: "https://demo.bergify.com/pro/landing-page-1/",
        class: "w-full rounded-lg"
    },
    {
        key: 3,
        name: "Landing Page 3",
        png: prolanding3PNG,
        link: "https://demo.bergify.com/pro/landing-page-2/",
        class: "w-full rounded-lg"
    },
    {
        key: 4,
        name: "Service Page 1",
        png: proservices1PNG,
        link: "https://demo.bergify.com/pro/services-1/",
        class: "w-full rounded-lg"
    },
    {
        key: 5,
        name: "Service Page 2",
        png: proservices2PNG,
        link: "https://demo.bergify.com/pro/services-2/",
        class: "w-full rounded-lg"
    },
    {
        key: 6,
        name: "Team Page 1",
        png: proteam1PNG,
        link: "https://demo.bergify.com/pro/team-1/",
        class: "w-full rounded-lg"
    },
    {
        key: 7,
        name: "Team Page 2",
        png: proteam2PNG,
        link: "https://demo.bergify.com/pro/team-2/",
        class: "w-full rounded-lg"
    },
    {
        key: 8,
        name: "Pricing 1",
        png: propricing1PNG,
        link: "https://demo.bergify.com/pro/pricing-1/",
        class: "w-full rounded-lg"
    },
    {
        key: 9,
        name: "Pricing 2",
        png: propricing2PNG,
        link: "https://demo.bergify.com/pro/pricing-2/",
        class: "w-full rounded-lg"
    },
    {
        key: 10,
        name: "Contact Page 1",
        png: procontact1PNG,
        link: "https://demo.bergify.com/pro/contact-1/",
        class: "w-full rounded-lg"
    },
    {
        key: 11,
        name: "Contact Page 2",
        png: procontact2PNG,
        link: "https://demo.bergify.com/pro/contact-2/",
        class: "w-full rounded-lg"
    },
    {
        key: 12,
        name: "Testimonials",
        png: protestimonialsPNG,
        link: "https://demo.bergify.com/pro/testimonials/",
        class: "w-full rounded-lg"
    },
    {
        key: 13,
        name: "Blog",
        png: problogPNG,
        link: "https://demo.bergify.com/blog/",
        class: "w-full rounded-lg"
    },
    {
        key: 14,
        name: "Post with sidebar",
        png: propostPNG,
        link: "https://demo.bergify.com/flowbite-a-figma-design-kit-built-for-integration-with-tailwind-css/",
        class: "w-full rounded-lg"
    },
    {
        key: 15,
        name: "404 File not found",
        png: pro404PNG,
        link: "https://demo.bergify.com/prdel/",
        class: "w-full rounded-lg"
    },
]
