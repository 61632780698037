import * as React from "react"
import { Helmet } from "react-helmet";

import ogImage from "./../images/og-image.jpg";

import Navbar from '../sections/navbar.js';
import Hero from '../sections/pro/hero.js';
import Features from '../sections/pro/features';
import ApplicationUI from '../sections/pro/application-ui';
// import Testimonials from '../sections/pro/testimonials.js';
import Pricing from '../sections/pro/pricing.js';
import FAQ from '../sections/pro/faq.js';
import Footer from '../sections/footer.js';

class ProPage extends React.Component {

    render() {
        return (
            <div id="overview">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Bergify PRO - Premium Wordpress Theme</title>
                    <link rel="canonical" href="https://www.bergify.com/pro/" />
                    <meta name="description" content="Take your Wordpress website to the next level with Bergify PRO." />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:site" content="@bergify_" />
                    <meta name="twitter:title" content="Bergify PRO - Premium Wordpress Theme" />
                    <meta name="twitter:description" content="Take your Wordpress website to the next level with Bergify PRO." />
                    <meta name="twitter:image" content={'https://www.bergify.com' + ogImage} />
                    <meta name="twitter:creator" content="@bergify_" />

                    <meta property="og:url" content="https://www.bergify.com/" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Bergify PRO - Premium Wordpress Theme" />
                    <meta property="og:description" content="Take your Wordpress website to the next level with Bergify PRO." />
                    <meta property="og:image" content={'https://www.bergify.com' + ogImage} />
                </Helmet>
                <Navbar></Navbar>
                <main>
                    <Hero></Hero>
                    <Features></Features>
                    <ApplicationUI></ApplicationUI>
                    {/* <Testimonials></Testimonials> */}
                    <Pricing></Pricing>
                    <FAQ></FAQ>
                    <Footer></Footer>
                </main>
            </div>
        )
    }
}
export default ProPage