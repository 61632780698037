import * as React from "react"
import { Link } from "gatsby"

class Pricing extends React.Component {

    openCheckoutPro = () => {
        window.Paddle.Checkout.open({
            product: 832276, // Production
            // product: 52127, // Sandbox
            allowQuantity: true,
            success: "/checkout/"
            // successCallback / string / Name of a Javascript function (or function reference) to call on checkout completion.
            // closeCallback / string / Name of a Javascript function to call on checkout close.
        });
    }

    render() {
        return (
            <section className="bg-white pb-16 lg:pb-24" id="pricing">
                <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <h2 className="font-bold lg:font-extrabold text-3xl lg:text-4xl lg:leading-snug text-gray-900 lg:text-center 2xl:px-48 mb-4">Which version is right for you?</h2>
                    <p className="text-gray-500 font-normal lg:text-center text-lg lg:text-xl mb-10 lg:mb-16">See how Bergify PRO compares to the free version.</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-16 bg-gray-50 rounded-t-lg p-8">
                        <div className="hidden md:block">
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="text-lg font-bold text-gray-900 mb-1">Bergify</h3>
                            <span className="inline-block text-4xl text-gray-900 font-extrabold mb-4">Free</span>
                            <p className="text-sm text-gray-500 mt-0 mb-4">Start creating websites with a free Wordpress block theme including full-page templates and pre-designed page sections.</p>
                            <a href="https://docs.bergify.com/" target="_blank" className="block w-full rounded-lg border border-gray-200 bg-white font-medium px-6 py-2 text-center text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">Get started</a>
                        </div>
                        <div className="mb-3 md:mb-0">
                            <h3 className="text-lg font-bold text-gray-900 mb-1">Bergify PRO</h3>
                            <span className="inline-block text-4xl text-gray-900 font-extrabold mb-4">$49</span> <span className="inline-block text-xl text-gray-700 font-semibold mb-4">/ year</span>
                            <p className="text-sm text-gray-500 mt-0 mb-4">Bergify PRO offers a comprehensive website design solution. It also provides one year of dedicated support and updates.</p>
                            {/* <a href="https://sandbox-buy.paddle.com/product/52127" data-product="52127" data-success="https://www.bergify.com/checkout/" className="paddle_button block w-full text-white bg-purple-600 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-xl text-base px-6 py-2 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center">Buy now</a> */}
                            <button onClick={() => { this.openCheckoutPro() }} className="paddle_button block w-full text-white bg-purple-600 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-xl text-base px-6 py-2 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center" data-theme="none">Buy now</button>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <div className="min-w-max overflow-hidden">
                            <div className="grid grid-cols-3 gap-x-16 bg-gray-100 border-t border-b border-gray-200 p-4 text-gray-900 text-sm font-medium">
                                <div>Features</div>
                                <div>Bergify</div>
                                <div>Bergify PRO</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500"><a href="#page-templates" class="text-blue-600 hover:underline">Full-page templates (patterns)</a></div>
                                <div>10</div>
                                <div>20+</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Page and Blog sections (patterns)</div>
                                <div>25</div>
                                <div>60+</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Custom (block) styles</div>
                                <div>8</div>
                                <div>18</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Global styles (skins)</div>
                                <div>Light, Dark and Grayscale</div>
                                <div>Light, Dark and Grayscale</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500"><a href="https://blog.bergify.com/mastering-website-wireframe-in-wordpress/" target="_blank" class="text-blue-600 hover:underline">Wireframing</a></div>
                                <div><svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg></div>
                                <div><svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg></div>
                            </div>

                            <div className=" grid grid-cols-3 gap-x-16 bg-gray-100 border-t border-b border-gray-200 p-4 text-gray-900 text-sm font-medium">
                                <div>Support</div>
                                <div>Bergify</div>
                                <div>Bergify PRO</div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Community</div>
                                <div><svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg></div>
                                <div><svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg></div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Bug reports & feature requests</div>
                                <div><svg className="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                <div><svg className="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg></div>
                            </div>
                            <div className="grid grid-cols-3 gap-x-16 px-4 py-5 text-sm text-gray-700 border-b border-gray-200">
                                <div className="text-gray-500">Support and updates duration</div>
                                <div><svg className="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></div>
                                <div>1 year</div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-x-16 bg-gray-50 rounded-t-lg p-8">
                        <div className="hidden md:block">
                        </div>
                        <div className="mb-8 md:mb-0">
                            <h3 className="text-lg font-bold text-gray-900 mb-1">Bergify</h3>
                            <span className="block text-4xl text-gray-900 font-extrabold mb-4">Free</span>
                            <a href="https://docs.bergify.com/" target="_blank" className="block w-full rounded-lg border border-gray-200 bg-white font-medium px-6 py-2 text-center text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">Get started</a>
                        </div>
                        <div className="mb-3 md:mb-0">
                            <h3 className="text-lg font-bold text-gray-900 mb-1">Bergify PRO</h3>
                            <span className="inline-block text-4xl text-gray-900 font-extrabold mb-4">$49</span> <span className="inline-block text-xl text-gray-700 font-semibold mb-4">/ year</span>
                            <button onClick={() => { this.openCheckoutPro() }} className="paddle_button block w-full text-white bg-purple-600 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-xl text-base px-6 py-2 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center" data-theme="none">Buy now</button>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Pricing
