import * as React from "react"
import pages from '../../data/pro-pages';

// markup
const ApplicationUI = () => {
    return (
        <section className="my-8 lg:my-24" id="page-templates">
            <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 lg:text-center 2xl:px-48 mb-4">Full-page templates</h2>
                <p className="text-gray-500 font-normal lg:text-center text-lg lg:text-xl xl:px-56 mb-10 lg:mb-16">More than 20 full-page templates - patterns are a perfect start when you create a new web page.</p>
                <div className="grid md:grid-cols-2 gap-8">
                    {pages.map(p => (
                        <a href={p.link} target="_blank" className="rounded-lg transition border border-gray-100 hover:border-white hover:shadow-lg bg-white">
                            <div className="bg-gray-50 rounded-t-lg py-2.5 px-5 flex justify-between items-center border-b border-gray-200">
                                <span className="font-medium text-gray-500 text-lg">{p.name}</span>
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                            </div>
                            <div className="flex justify-center items-center">
                                <img className={p.class} src={p.png} alt={p.name} />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default ApplicationUI
