import * as React from "react"
import ImageCarousel from "../../data/image-carousel"
import customStyles from "./../../images/pro/custom-styles-button.png"
import sections from "./../../images/pro/patterns.png"
import styleVariations from "./../../images/style-variations.png"


class Features extends React.Component {

    render() {
        return (
            <section className="py-4 lg:py-12 bg-gray-50" id="whats-inside">

                <div className="py-4 lg:py-12 max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-6 items-center">
                        <div className="mb-5 lg:mb-0 lg:col-span-2">
                            <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4">Full-page templates</h2>
                            <p className="text-gray-500 font-normal text-lg lg:text-xl mb-6 lg:mb-8">Need a custom landing or service page? Bergify PRO offers a diverse selection of pages for your business website, including stunning homepage designs and professional contact pages.</p>
                            <div className="block sm:flex">
                                <a href="#page-templates" className="text-purple-600 lg:-text-lg font-medium">Preview page templates <svg className="w-4 h-4 inline" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></a>
                            </div>
                        </div>
                        <div id="laptop-carousel" className="lg:col-span-3 mb-12 lg:mb-0">
                            <div><ImageCarousel /></div>
                        </div>
                    </div>
                </div>

                <div className="py-4 lg:py-12 max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-0 lg:gap-6 items-center">
                        <img className="lg:col-span-3 order-2 lg:order-1" src={sections} alt="Page sections - Patterns" />
                        <div className="mb-5 lg:mb-0 lg:col-span-2 order-1 lg:order-2">
                            <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4">Pre-designed Page and Blog sections</h2>
                            <p className="text-gray-500 font-normal text-lg lg:text-xl mb-6 lg:mb-8">Effortlessly create your custom webpage with our collection of ready-to-use sections with a unified design. Simply insert pre-designed sections like Hero, Pricing, FAQ, Team, Call-to-action, and more into your page, and watch your page come to life in a matter of minutes.</p>
                        </div>
                    </div>
                </div>

                <div className="py-4 lg:py-12 max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-0 lg:gap-6 items-center">
                        <div className="lg:col-span-2">
                            <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4">Custom block styles</h2>
                            <p className="text-gray-500 font-normal text-lg lg:text-xl mb-6 lg:mb-8">The blocks within the Wordpress editor seamlessly align with the unified design of the Bergify PRO theme. From buttons to columns, lists, and other elements, every component maintains a consistent and visually cohesive aesthetic.</p>
                        </div>
                        <img className="lg:col-span-3" src={customStyles} alt="Custom block styles for Buttons" />
                    </div>
                </div>

                <div className="py-4 lg:py-12 max-w-screen-xl px-4 lg:px-12 mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-5 gap-0 lg:gap-24 items-center">
                        <img className="lg:col-span-2 order-2 lg:order-1" src={styleVariations} alt="Style variations" />
                        <div className="mb-5 lg:mb-0 lg:col-span-3 order-1 lg:order-2">
                            <h2 className="font-bold lg:font-extrabold text-2xl lg:text-4xl lg:leading-snug text-gray-900 mb-4">Light, Dark and Grayscale modes</h2>
                            <p className="text-gray-500 font-normal text-lg lg:text-xl mb-6 lg:mb-8">The Bergify PRO theme excels in both Light and Dark modes. The special Grayscale style goes beyond a simple design feature. It stands as a cornerstone for efficient wireframing within WordPress.</p>
                        </div>
                    </div>
                </div>

            </section>
        )
    }

}

export default Features
